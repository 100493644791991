import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild,
    Renderer2,
    OnDestroy,
} from '@angular/core';
import { SIDE_MENU_WIDTH } from '@app/shared/constants';
import { ResizableSidebarService } from '@app/services/resizable-sidebar/resizable-sidebar.service';
import { ScrollableBodyService } from '@services/scrollable-body/scrollable-body.service';

@Component({
    selector: 'un-content-wrapper',
    templateUrl: './content-wrapper.component.html',
    styleUrls: ['./content-wrapper.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentWrapperComponent implements AfterViewInit, OnDestroy {
    @ViewChild('leftSidebarWrapper')
    private readonly leftSidebarWrapper!: ElementRef;

    @ViewChild('leftResizer')
    private readonly leftResizer!: ElementRef;

    @ViewChild('rightSidebarWrapper')
    private readonly rightSidebarWrapper!: ElementRef;

    showLeftSidebar = false;
    showRightSidebar = false;
    isResizable = false;

    scrollable$ = this.scrollableBodyService.scrollable$;

    mouseDownListener?: () => void;
    mouseMoveListener?: () => void;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly scrollableBodyService: ScrollableBodyService,
        private readonly resizableSidebarService: ResizableSidebarService,
        private readonly renderer: Renderer2,
    ) {}

    ngAfterViewInit(): void {
        const localWidth = this.resizableSidebarService.getLocalWidth();

        this.resizableSidebarService.resizable$.subscribe((value) => (this.isResizable = value));

        if (this.isResizable) {
            this.leftSidebarWrapper.nativeElement.style.width = localWidth;
        }

        this.showLeftSidebar = this.leftSidebarWrapper.nativeElement?.children?.length > 0;
        this.showRightSidebar = this.rightSidebarWrapper.nativeElement?.children?.length > 0;

        this.changeDetectorRef.detectChanges();

        this.mouseDownListener = this.renderer.listen(this.leftResizer.nativeElement, 'mousedown', () => {
            this.mouseMoveListener?.();

            this.mouseMoveListener = this.renderer.listen(document, 'mousemove', (event) => {
                const size = `${event.x - SIDE_MENU_WIDTH}px`;
                this.resizableSidebarService.setLocalWidth(size);
                this.leftSidebarWrapper.nativeElement.style.width = size;
            });

            const mUp = this.renderer.listen(document, 'mouseup', () => {
                this.mouseMoveListener?.();
                mUp();
            });
        });
    }

    ngOnDestroy(): void {
        this.mouseDownListener?.();
        this.mouseMoveListener?.();
    }
}
