import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { Subject, finalize } from 'rxjs';

import { ProfileService } from '@app/core/services/profile.service';
import { ContentWrapperModule } from '@app/shared/components/content-wrapper/content-wrapper.module';
import { ImageUploaderComponent } from '@app/shared/components/files/image-uploader/image-uploader.component';
import { FormWrapperModule } from '@app/shared/components/form-wrapper/form-wrapper.module';
import { trackBySelectItem } from '@app/shared/utils/directives/track-by-select-item';

@UntilDestroy()
@Component({
    selector: 'un-user-profile',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,

        NzButtonModule,
        NzDividerModule,
        NzFormModule,
        NzInputModule,
        NzSelectModule,

        ContentWrapperModule,
        FormWrapperModule,
        ImageUploaderComponent,
    ],
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent implements OnInit {
    readonly emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

    readonly form = this.formBuilder.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        position: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
        phone: ['', [Validators.required]],
        photoId: [''],
        id: [''],
    });

    readonly trackBySelectItem = trackBySelectItem;
    readonly loading$ = new Subject<boolean>();

    roles: string[] = [];
    isSelf = false;

    constructor(
        // private readonly router: Router,
        private readonly formBuilder: NonNullableFormBuilder,
        private readonly cdr: ChangeDetectorRef,
        private readonly profileService: ProfileService,
    ) {}

    ngOnInit(): void {
        this.profileService.current$.pipe(untilDestroyed(this)).subscribe((data) => {
            if (data) {
                const { role, photo, ...rest } = data;
                this.roles = role.map(({ name }) => name).sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
                this.form.patchValue({ ...rest, photoId: photo?.id ?? '' });
            } else {
                this.form.reset();
            }

            this.cdr.markForCheck();
        });
    }

    onSubmitEditor(): void {
        if (this.form.valid) {
            this.loading$.next(true);

            this.profileService
                .update(this.form.value)
                .pipe(
                    finalize(() => {
                        this.loading$.next(false);
                    }),
                    untilDestroyed(this),
                )
                .subscribe(() => {
                    this.onCancel();
                });
        }
    }

    onCancel(): void {
        history.back();
    }
}
