import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ResizableSidebarService {
    private readonly resizable$$ = new BehaviorSubject<boolean>(false);

    readonly resizable$: Observable<boolean> = this.resizable$$.asObservable();

    setResizable(isResizable: boolean): void {
        this.resizable$$.next(isResizable);
    }

    setLocalWidth(width: string): void {
        localStorage.setItem('sidebar', width);
    }

    getLocalWidth(): string {
        return localStorage.getItem('sidebar') ?? '270px';
    }
}
