import { Routes } from '@angular/router';

import { BaseLayoutComponent, EmptyLayoutComponent } from '@layout/containers';
import { hasAuthenticated } from './core/guards/auth.guard';
import { defaultRouteGuard, developOnly, hasAnyPermission } from './core/guards/permissions.guard';
import { Error403Component } from './modules/errors/components/error403/error-403.component';
import { Error404Component } from './modules/errors/components/error404/error-404.component';
import {
    AdministrationRoute,
    FacilityRoute,
    JobsRoute,
    JournalRoute,
    ModelsRoute,
    MonitoringRoute,
    UsersRoute,
    FacilityGroupsRoute,
    RootRoutes,
    ForbiddenRoute,
    UniodRoute,
} from './routes';
import { UserProfileComponent } from './layout/components/user-profile/user-profile.component';

// const DefaultRoute = MonitoringRoute.path;

export const APP_MODULE_ROUTES: Routes = [
    {
        path: '',
        component: BaseLayoutComponent,
        canActivate: [hasAuthenticated],
        children: [
            {
                path: '',
                pathMatch: 'full',
                canActivate: [defaultRouteGuard(RootRoutes)],
                component: EmptyLayoutComponent,
            },
            {
                path: 'profile',
                component: UserProfileComponent,
            },
            {
                ...ModelsRoute,
                loadChildren: () => import('@modules/models/models.module').then((m) => m.ModelsModule),
                canActivate: [hasAnyPermission, developOnly],
            },
            {
                ...JobsRoute,
                loadChildren: () => import('@modules/jobs/jobs.module').then((m) => m.JobsModule),
                canActivate: [hasAnyPermission, developOnly],
            },
            {
                ...JournalRoute,
                loadChildren: () => import('@modules/journal/journal.module').then((m) => m.JournalModule),
                canActivate: [hasAnyPermission, developOnly],
            },
            {
                ...MonitoringRoute,
                loadChildren: () => import('@modules/monitoring/monitoring.module').then((m) => m.MonitoringModule),
                canActivate: [hasAnyPermission, developOnly],
            },
            {
                ...FacilityRoute,
                loadChildren: () => import('@modules/facilities/facilities.module').then((m) => m.FacilitiesModule),
                canActivate: [hasAnyPermission, developOnly],
            },
            {
                ...UsersRoute,
                loadChildren: () => import('@modules/users/users.module').then((m) => m.UsersModule),
                canActivate: [hasAnyPermission, developOnly],
            },
            {
                ...AdministrationRoute,
                loadChildren: () =>
                    import('@modules/administration/administration.module').then((m) => m.AdministrationModule),
                canActivate: [hasAnyPermission, developOnly],
            },
            {
                ...FacilityGroupsRoute,
                loadChildren: () => import('@modules/groups/groups.module').then((m) => m.GroupsModule),
                canActivate: [hasAnyPermission],
            },
        ],
    },
    {
        path: 'auth',
        component: EmptyLayoutComponent,
        loadChildren: () => import('@modules/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        ...UniodRoute,
        loadChildren: () => import('@modules/uniod/uniod.module').then((m) => m.UniodModule),
    },
    {
        ...ForbiddenRoute,
        component: BaseLayoutComponent,
        children: [
            {
                path: '',
                component: Error403Component,
                title: '403 – Доступ запрещен',
            },
        ],
    },
    {
        path: '**',
        component: BaseLayoutComponent,
        children: [
            {
                path: '',
                component: Error404Component,
                title: '404 – Страница не найдена',
            },
        ],
    },
];
