import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
    BehaviorSubject,
    bufferTime,
    concatMap,
    delay,
    distinctUntilChanged,
    filter,
    from,
    interval,
    map,
    of,
    switchMap,
    tap,
} from 'rxjs';

import { Router } from '@angular/router';
import { UndEventLevel, UndFullEvent } from '@api/uniod/types/events';
import { ProfileService } from '@app-core/services/profile.service';
import { JobPermissions, UniodEventPermissions } from '@app/core/permissions';
import { AppConfigService } from '@app/core/services/app-config.service';
import { NotificationsService } from '@app/layout/services/notifications.service';
import { BreadcrumbsService } from '@app/services/breadcrumbs.service';
import { UniodWsEventsService } from '@app/services/ws/uniod-ws-events.service';
import { NotificationsComponent } from '@layout/components/notifications/notifications.component';
import { Breadcrumb } from '@un-types/breadcrumb';

@UntilDestroy()
@Component({
    selector: 'un-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
    readonly pageTitle$ = new BehaviorSubject<string>('');
    readonly total$ = this.notificationsService.total$;

    showJobNotifications = false;

    @ViewChild('notificationsTrigger')
    private readonly notificationsTrigger!: ElementRef;

    @ViewChild('errorNotification', { static: false })
    // eslint-disable-next-line @typescript-eslint/ban-types
    private readonly errorNotification!: TemplateRef<{}>;

    private overlayRef?: OverlayRef;

    private eventsCash: UndFullEvent[] = [];

    constructor(
        public readonly profileService: ProfileService,
        private readonly overlay: Overlay,
        private readonly router: Router,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly breadcrumbsService: BreadcrumbsService,
        private readonly notificationsService: NotificationsService,
        private readonly uniodEvents: UniodWsEventsService,
        private readonly nzNotify: NzNotificationService,
        private readonly configSrv: AppConfigService,
    ) {}

    ngOnInit(): void {
        interval(60000).subscribe(() => {
            if (this.eventsCash.length > 0) {
                this.eventsCash.shift();
            }
        });

        if (!this.profileService.snaphot) {
            this.profileService.getCurrent().subscribe();
        }

        this.breadcrumbsService.breadcrumbs$.pipe(untilDestroyed(this)).subscribe((breadcrumbs) => {
            this.updatePageTitle(breadcrumbs);
        });

        this.uniodEvents.events$
            .pipe(
                map((e) => e as UndFullEvent),
                filter(
                    ({ level, fullMsg, uniodName }: UndFullEvent) =>
                        level === UndEventLevel.Error && !!fullMsg && !!uniodName,
                ),
                bufferTime(5000),
                concatMap((events) =>
                    from(events).pipe(
                        concatMap((evt) =>
                            of(evt).pipe(
                                tap(() => {
                                    const isEventCached = this.eventsCash.find((item) => {
                                        return item.uniodId === evt.uniodId && item.code === evt.code;
                                    });

                                    if (!isEventCached) {
                                        this.nzNotify.template(this.errorNotification, {
                                            nzData: evt,
                                            nzDuration: 10000,
                                        });

                                        if (this.eventsCash.length > 1000) {
                                            this.eventsCash = [...this.eventsCash.slice(500)];
                                        }

                                        this.eventsCash.push(evt);
                                    }
                                }),
                                delay(1500),
                            ),
                        ),
                    ),
                ),
                untilDestroyed(this),
            )
            .subscribe();

        this.profileService.current$.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((profile) => {
            if (
                profile?.hasAnyPermission([
                    UniodEventPermissions.searchActual,
                    UniodEventPermissions.searchActualForList,
                ])
            ) {
                this.uniodEvents.initConnection('00000000-0000-0000-0000-000000000000');
                this.uniodEvents.open();
            } else {
                this.uniodEvents.close();
            }

            this.showJobNotifications = !!profile?.hasAnyPermission([JobPermissions.getOne]);

            if (this.showJobNotifications) {
                this.notificationsService.init();
                this.notificationsService.needUpdateList();
            } else {
                this.notificationsService.close();
            }
        });

        this.profileService.current$
            .pipe(
                distinctUntilChanged(),
                switchMap((profile) => (profile?.id ? this.configSrv.getCurrent() : this.configSrv.reset())),
                untilDestroyed(this),
            )
            .subscribe();
    }

    onClickProfile(): void {
        this.router.navigate(['/profile']);
    }

    onLogout(): void {
        localStorage.removeItem('series');
        localStorage.removeItem('rememberMeRefreshToken');
        localStorage.removeItem('rememberMe');
        this.uniodEvents.close();
        this.notificationsService.close();
        this.profileService.logout();
    }

    onToggleNotifications(): void {
        if (this.overlayRef) {
            this.closeNotifications();
        } else {
            this.openNotifications();
        }
    }

    private openNotifications(): void {
        const overlayPositionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.notificationsTrigger.nativeElement)
            .withLockedPosition()
            .withPositions([
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top',
                },
            ]);

        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            positionStrategy: overlayPositionStrategy,
        });

        this.overlayRef
            .backdropClick()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.closeNotifications();
            });

        this.overlayRef.attach(new ComponentPortal(NotificationsComponent, this.viewContainerRef));
    }

    private closeNotifications(): void {
        this.overlayRef?.detach();
        this.overlayRef = undefined;
    }

    private updatePageTitle(breadcrumbs: Breadcrumb[]): void {
        this.pageTitle$.next(breadcrumbs.length === 1 ? breadcrumbs[0].label : '');
    }
}
